import React, { useEffect, useState } from "react";

import { useLazyQuery } from "@apollo/client";
import { Link } from "gatsby";

import Accordion from "@/components/common/accordion";
import BulletBlock from "@/components/common/bulletBlock";
import CardBlock from "@/components/common/cardBlock";
import LeadForm from "@/components/common/leadForm";
import Review from "@/components/common/review";
import ShowcaseBlock from "@/components/common/showcaseBlock";
import StepBlock from "@/components/common/stepBlock";
import StickyButton from "@/components/common/stickyButton";
import useNavigateToPrivatePath from "@/hooks/useNavigateToPrivatePath";
import { customMarketingHandler } from "@/marketing/utils";
import { isNotNullOrUndefined, sortExpertEdgesByStatus } from "@/utils/commonUtils";
import { cleanupFromDangerousTags } from "@/utils/stringUtils";

import { AllResolutionsImage } from "../allResolutionsImage";
import ButtonLink from "../buttonLink";
import CardTable from "../cardTable";
import { SHOWN_EXPERTS_COUNT } from "../constants";
import ExpertsBlock from "../expertsBlock";
import QuizBlock from "../quizBlock";
import { buildExpertList, buildSpecificExpertList } from "../utils";

import {
  GetConstructorExperts,
  GetConstructorExperts_getExperts_edges,
} from "./graphql/__generated__/GetConstructorExperts";
import { GET_CONSTRUCTOR_EXPERTS } from "./graphql/GET_CONSTRUCTOR_EXPERTS";
import { ConstructorProps, ZoneItemEnum, ZoneItemType } from "./types";
import {
  convertToBulletBlockItems,
  convertToCardBlockItems,
  convertToCarouselItems,
  convertToLabelItems,
  convertToLeadFormDates,
  convertToLeadFormEmails,
  convertToLeadFormPhones,
  convertToReviewItem,
  convertToShowcaseImage,
  convertToSlantedLabelItems,
  convertToStepBlockItems,
} from "./utils";

import "../styles.scss";
import "./styles.scss";

const Constructor = (props: ConstructorProps) => {
  const {
    zone,
    expertsInitial,
  } = props;

  const { navigateToPrivatePath } = useNavigateToPrivatePath();

  const [experts, setExperts] = useState<GetConstructorExperts_getExperts_edges[]>(
    sortExpertEdgesByStatus(expertsInitial) as GetConstructorExperts_getExperts_edges[],
  );

  const [getConstructorExperts, {
    data,
    error,
    loading,
  }] = useLazyQuery<GetConstructorExperts>(GET_CONSTRUCTOR_EXPERTS, {
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    if (zone?.find((zoneItem: ZoneItemType) =>
      [
        ZoneItemEnum.Seo_ComponentListingSimpleExpertsListing as string,
        ZoneItemEnum.Seo_ComponentListingCustomExpertsListing as string,
      ].includes(zoneItem.__typename))) {
      getConstructorExperts();
    }
  }, [getConstructorExperts, zone]);

  useEffect(() => {
    if (data && !error && !loading) {
      if (data.getExperts?.edges) {
        // todo: ask to make non-null experts on backend. Until then this variable will be red
        const nonNullExperts: GetConstructorExperts_getExperts_edges[] = data
          .getExperts.edges.filter(
            isNotNullOrUndefined,
          );

        // todo: make correct sorting on backand and use just experts?.edges
        setExperts(sortExpertEdgesByStatus(nonNullExperts) as GetConstructorExperts_getExperts_edges[]);
      }
    }
  }, [data, error, loading]);

  return (
    <div className="custom-block">
      {zone?.map((zoneItem) => {
        if (!zoneItem) {
          return null;
        }

        switch (zoneItem.__typename) {
          case (ZoneItemEnum.Seo_ComponentBasicTextField): {
            const zoneData = zoneItem as Queries.Seo_ComponentBasicTextField;
            return (
              <div className="custom-block__typography-component" key={`${zoneItem.__typename}${zoneData.id}`}>
                <div
                  id={zoneData.textHtmlId || undefined}
                  className="seo-typography"
                  dangerouslySetInnerHTML={{
                    __html: cleanupFromDangerousTags(zoneData.textContent || ""),
                  }}
                />
              </div>
            );
          }
          case (ZoneItemEnum.Seo_ComponentBasicVideoItem): {
            const zoneData = zoneItem as Queries.Seo_ComponentBasicVideoItem;
            return (
              <div className="custom-block__video-component" key={`${zoneItem.__typename}${zoneData.id}`}>
                <div className="responsive-video-16-9">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: cleanupFromDangerousTags(JSON.parse(zoneData.video || "")?.rawData?.html || ""),
                    }}
                  />
                </div>
              </div>
            );
          }
          case (ZoneItemEnum.Seo_ComponentButtonButtonLink): {
            const zoneData = zoneItem as Queries.Seo_ComponentButtonButtonLink;
            return (
              <div
                className="custom-block__button-component"
                key={`${zoneItem.__typename}${zoneData.id}`}
              >
                <ButtonLink data={zoneData} />
              </div>
            );
          }
          case (ZoneItemEnum.Seo_ComponentButtonStickyButtonLink): {
            const zoneData = zoneItem as Queries.Seo_ComponentButtonStickyButtonLink;
            return (
              <StickyButton
                onClick={() => {
                  if (zoneData.stickyButtonMetricsId) {
                    customMarketingHandler(zoneData.stickyButtonMetricsId);
                  }
                  navigateToPrivatePath(zoneData.stickyButtonUrl);
                }}
                stickyButtonText={zoneData.stickyButtonText}
                stickyButtonSubtext={zoneData.stickyButtonSubtext}
                stickyButtonShowLabel={zoneData.stickyButtonShowLabel}
                key={`${zoneItem.__typename}${zoneData.id}`}
              />
            );
          }
          case (ZoneItemEnum.Seo_ComponentListingSimpleExpertsListing): {
            const zoneData = zoneItem as Queries.Seo_ComponentListingSimpleExpertsListing;
            return (
              <div className="custom-block__listing-component" key={`${zoneItem.__typename}${zoneData.id}`}>
                <ExpertsBlock
                  experts={buildExpertList(experts, SHOWN_EXPERTS_COUNT)}
                  showCatalogButton
                />
              </div>
            );
          }
          case (ZoneItemEnum.Seo_ComponentListingCustomExpertsListing): {
            const zoneData = zoneItem as Queries.Seo_ComponentListingCustomExpertsListing;
            return (
              <div className="custom-block__listing-component" key={`${zoneItem.__typename}${zoneData.id}`}>
                <ExpertsBlock
                  experts={
                      buildSpecificExpertList(
                        zoneData?.expertIds
                          ?.map((item) =>
                            item?.expertId)
                          || [],
                        experts,
                      )
                    }
                  showCatalogButton={false}
                />
              </div>
            );
          }
          case (ZoneItemEnum.Seo_ComponentBlockCardBlock): {
            const zoneData = zoneItem as Queries.Seo_ComponentBlockCardBlock;
            return (
              <div className="custom-block__card-component" key={`${zoneItem.__typename}${zoneData.id}`}>
                <CardBlock
                  items={convertToCardBlockItems(zoneData.cardBlockItems)}
                />
              </div>
            );
          }
          case (ZoneItemEnum.Seo_ComponentBlockBulletBlock): {
            const zoneData = zoneItem as Queries.Seo_ComponentBlockBulletBlock;
            return (
              <div className="custom-block__bullet-component" key={`${zoneItem.__typename}${zoneData.id}`}>
                <BulletBlock
                  items={convertToBulletBlockItems(zoneData.bulletBlockItems)}
                />
              </div>
            );
          }
          case (ZoneItemEnum.Seo_ComponentBlockStepBlock): {
            const zoneData = zoneItem as Queries.Seo_ComponentBlockStepBlock;
            return (
              <div className="custom-block__step-component" key={`${zoneItem.__typename}${zoneData.id}`}>
                <StepBlock items={convertToStepBlockItems(zoneData.stepBlockItems)} />
              </div>
            );
          }
          case (ZoneItemEnum.Seo_ComponentBlockShowcaseBlock): {
            const zoneData = zoneItem as Queries.Seo_ComponentBlockShowcaseBlock;
            return (
              <div className="custom-block__showcase-component" key={`${zoneItem.__typename}${zoneData.id}`}>
                <ShowcaseBlock
                  title={zoneData.showcaseBlockTitle}
                  carouselItems={convertToCarouselItems(zoneData.showcaseBlockCarousel)}
                  buttonText={zoneData?.showcaseBlockButton.buttonText}
                  buttonSubtext={zoneData?.showcaseBlockButton.buttonSubtext}
                  buttonLink={zoneData?.showcaseBlockButton.buttonUrl}
                  labels={convertToLabelItems(zoneData.showcaseBlockLabels)}
                  slantedlabels={convertToSlantedLabelItems(zoneData.showcaseBlockSlantedLabels)}
                  imageData={convertToShowcaseImage(zoneData.showcaseBlockImage)}
                  buttonMetricsId={zoneData.showcaseBlockButton?.buttonMetricsId}
                />
              </div>
            );
          }
          case (ZoneItemEnum.Seo_ComponentBlockReviewBlock): {
            const zoneData = zoneItem as Queries.Seo_ComponentBlockReviewBlock;
            return (
              <div className="custom-block__review-component" key={`${zoneItem.__typename}${zoneData.id}`}>
                {zoneData?.reviewBlockItems.map((reviewBlockItem) =>
                  (
                    reviewBlockItem ? (
                      <Review
                        review={convertToReviewItem(reviewBlockItem)}
                        key={reviewBlockItem?.id}
                      />
                    ) : null
                  ))}
              </div>
            );
          }
          case (ZoneItemEnum.Seo_ComponentBlockAccordionBlock): {
            const zoneData = zoneItem as Queries.Seo_ComponentBlockAccordionBlock;
            return (
              <div className="custom-block__accordion-component" key={`${zoneItem.__typename}${zoneData.id}`}>
                {zoneData?.accordionBlockItems.map((accordionBlockItem) =>
                  (
                    <Accordion
                      key={accordionBlockItem?.id}
                      summary={accordionBlockItem?.accordionBlockTitle || ""}
                      details={accordionBlockItem?.accordionBlockDescription || ""}
                    />
                  ))}
              </div>
            );
          }
          case (ZoneItemEnum.Seo_ComponentFormLeadForm): {
            const zoneData = zoneItem as Queries.Seo_ComponentFormLeadForm;
            const phoneItems = convertToLeadFormPhones(zoneData.leadFormPhones, 0);
            const emailItems = convertToLeadFormEmails(zoneData.leadFormEmails, phoneItems.length);
            const dateItems = convertToLeadFormDates(
              zoneData.leadFormDates,
              phoneItems.length + emailItems.length,
            );
            const fieldItems = [...phoneItems, ...emailItems, ...dateItems];

            return (
              <div className="custom-block__lead-component" key={`${zoneItem.__typename}${zoneData.id}`}>
                <LeadForm
                  formId={zoneData?.leadFormId}
                  metricsId={zoneData?.leadFormMetricsId}
                  redirectUrl={zoneData?.leadFormRedirectUrl}
                  title={zoneData?.leadFormTitle}
                  description={zoneData?.leadFormDescription}
                  fieldItems={fieldItems}
                  buttonText={zoneData?.leadFormButtonText}
                  buttonSubtext={zoneData?.leadFormButtonSubtext}
                  successTitle={zoneData?.leadFormSuccessTitle}
                  successDescription={zoneData?.leadFormSuccessDescription}
                  backgroundUrl={zoneData?.leadFormBackground?.data?.attributes?.url}
                  mobileBackgroundUrl={zoneData?.leadFormMobileBackground?.data?.attributes?.url}
                />
              </div>
            );
          }
          case (ZoneItemEnum.Seo_ComponentBasicQuizItem): {
            const zoneData = zoneItem as Queries.Seo_ComponentBasicQuizItem;
            return (
              <div className="custom-block__quiz-component" key={`${zoneItem.__typename}${zoneData.id}`}>
                <QuizBlock quizId={zoneData.quizId} />
              </div>
            );
          }
          case (ZoneItemEnum.Seo_ComponentBlockCardTable): {
            const zoneData = zoneItem as Queries.Seo_ComponentBlockCardTable;
            return (
              <div className="custom-block__card-table-component" key={`${zoneItem.__typename}${zoneData.id}`}>
                <CardTable data={zoneData} />
              </div>
            );
          }
          case (ZoneItemEnum.Seo_ComponentBlockSectionBlock): {
            const zoneData = zoneItem as unknown as Queries.Seo_ComponentBlockSectionBlock;
            const sectionZone = zoneData.section?.data?.attributes?.zone as ZoneItemType[];
            return (
              <div className="custom-block__section-block-component" key={`${zoneItem.__typename}${zoneData.id}`}>
                <Constructor zone={sectionZone} expertsInitial={expertsInitial} />
              </div>
            );
          }
          case (ZoneItemEnum.Seo_ComponentBasicImageLink): {
            const zoneData = zoneItem as unknown as Queries.Seo_ComponentBasicImageLink;
            if (zoneData.imageLinkUrl) {
              return (
                <Link to={zoneData.imageLinkUrl} className="custom-block__image-link">
                  <AllResolutionsImage
                    imageData={zoneData.imageLinkMobileImage}
                    imageDataDesktop={zoneData.imageLinkDesktopImage}
                  />
                </Link>
              );
            }
            return (
              <div className="custom-block__image-link">
                <AllResolutionsImage
                  imageData={zoneData.imageLinkMobileImage}
                  imageDataDesktop={zoneData.imageLinkDesktopImage}
                />
              </div>
            );
          }
          default: return null;
        }
      })}
    </div>
  );
};

export default Constructor;

import { GetConstructorExperts_getExperts_edges } from "./graphql/__generated__/GetConstructorExperts";

export enum LeadFormFieldEnum {
  Phone = "Phone",
  Email = "Email",
  Date = "Date"
}

export type ZoneItemType = {
  __typename: string
} & Queries.Seo_SectionZoneDynamicZone;

export enum ZoneItemEnum {
  Seo_ComponentBasicTextField = "Seo_ComponentBasicTextField",
  Seo_ComponentBasicVideoItem = "Seo_ComponentBasicVideoItem",
  Seo_ComponentButtonButtonLink = "Seo_ComponentButtonButtonLink",
  Seo_ComponentButtonStickyButtonLink = "Seo_ComponentButtonStickyButtonLink",
  Seo_ComponentListingSimpleExpertsListing = "Seo_ComponentListingSimpleExpertsListing",
  Seo_ComponentListingCustomExpertsListing = "Seo_ComponentListingCustomExpertsListing",
  Seo_ComponentBlockCardBlock = "Seo_ComponentBlockCardBlock",
  Seo_ComponentBlockBulletBlock = "Seo_ComponentBlockBulletBlock",
  Seo_ComponentBlockStepBlock = "Seo_ComponentBlockStepBlock",
  Seo_ComponentBlockShowcaseBlock = "Seo_ComponentBlockShowcaseBlock",
  Seo_ComponentBlockReviewBlock = "Seo_ComponentBlockReviewBlock",
  Seo_ComponentBlockAccordionBlock = "Seo_ComponentBlockAccordionBlock",
  Seo_ComponentFormLeadForm = "Seo_ComponentFormLeadForm",
  Seo_ComponentBasicQuizItem = "Seo_ComponentBasicQuizItem",
  Seo_ComponentBlockCardTable = "Seo_ComponentBlockCardTable",
  Seo_ComponentBlockSectionBlock = "Seo_ComponentBlockSectionBlock",
  Seo_ComponentBasicImageLink = "Seo_ComponentBasicImageLink",
}

export type ConstructorProps = {
    zone: Queries.Maybe<readonly Queries.Maybe<ZoneItemType>[]> | undefined;
    expertsInitial: GetConstructorExperts_getExperts_edges[];
};

import React from "react";

import { Link } from "gatsby";

import { Button, ButtonSizeEnum } from "@/components/common/button";
import useNavigateToPrivatePath from "@/hooks/useNavigateToPrivatePath";
import { customMarketingHandler } from "@/marketing/utils";

import { ButtonLinkProps } from "./types";

import "./styles.scss";

const ButtonLink = (props: ButtonLinkProps) => {
  const { data, size = ButtonSizeEnum.Large } = props;
  const { navigateToPrivatePath } = useNavigateToPrivatePath();

  return (
    <div className={`button-link${
      data.buttonIsCentered
        ? " button-link--centered"
        : ""}`}
    >

      <Link to={data.buttonUrl}>
        <Button
          text={data.buttonText}
          subtext={data.buttonSubtext}
          className="custom-block__button"
          size={size}
          onClick={() => {
            if (data.buttonMetricsId) {
              customMarketingHandler(data.buttonMetricsId);
            }
            navigateToPrivatePath(data.buttonUrl);
          }}
        />
      </Link>
    </div>
  );
};

export default ButtonLink;

import React from "react";

import { Link } from "gatsby";
import { Helmet } from "react-helmet";

import { BreadcrumbsProps } from "./types";
import { breadcrumbsToJsonLd, formatBreadcrumbs } from "./utils";
import "./styles.scss";

const Breadcrumbs = (props: BreadcrumbsProps) => {
  const {
    seoId,
    url,
    breadcrumbDisplayName = "",
    breadcrumbs = [],
    urlPrefix,
    showOnPage = true,
  } = props;

  const formattedBreadcrumbs = formatBreadcrumbs(
    seoId,
    url,
    breadcrumbDisplayName,
    breadcrumbs,
    urlPrefix,
  );
  const ldjsonBreadcrumbsObject = breadcrumbsToJsonLd(formattedBreadcrumbs);

  return (
    <>
      <Helmet>
        <script type="application/ld+json">{JSON.stringify(ldjsonBreadcrumbsObject)}</script>
      </Helmet>
      {showOnPage && (
        <ul className="breadcrumbs">
          {formattedBreadcrumbs.map((breadcrumb, index) => {
            if (formattedBreadcrumbs.length !== index + 1) {
              return (
                <li className="breadcrumbs__item" key={`${breadcrumb.displayName}_${breadcrumb.id}`}>
                  <Link to={breadcrumb.url}>{breadcrumb.displayName}</Link>
                </li>
              );
            }
            return (
              <li className="breadcrumbs__item" key={`${breadcrumb.displayName}_${breadcrumb.id}`}>
                <span>{breadcrumb.displayName}</span>
              </li>
            );
          })}
        </ul>
      )}
    </>
  );
};

export default Breadcrumbs;

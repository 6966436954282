import React, { useMemo } from "react";

import { Helmet } from "react-helmet";

import { FeedbackSlider, IconSizeEnum } from "@/components/common/feedbackSlider";
import { Icon, IconTypeEnum } from "@/components/common/icon";
import { convertToDateLiteraryShortened } from "@/utils/dateUtils";

import { ReviewProps } from "./types";
import "./styles.scss";

const Review = ({ review }: ReviewProps) => {
  if (!review) {
    return null;
  }

  const dateString = review.createdAtTimestamp
    ? convertToDateLiteraryShortened(review.createdAtTimestamp)
    : null;
  const userName = !!review.authorUser.name && !review.authorUser.name.match(/ID\d+/)
    ? review.authorUser.name
    : "Имя скрыто";

  const formatJsonLdForReview = useMemo(() =>
    (review
      ? {
        "@context": "https://schema.org/",
        "@type": "Review",
        itemReviewed: {
          "@type": "Expert",
          image: `${review?.authorUser.image.onex}`,
        },
        reviewRating: {
          "@type": "Rating",
          ratingValue: `${review.rating}`,
        },
        reviewBody: `${review.text}`,
        author: {
          "@type": "Person",
          name: `${review.authorUser.name}`,
        },
      }
      : {}), [review]);

  return (
    <>
      <Helmet>
        <script type="application/ld+json">{JSON.stringify(formatJsonLdForReview)}</script>
      </Helmet>
      <div className="expert-review" itemScope itemType="http://schema.org/Review">
        <div className="expert-review__top">
          <div className="expert-review__left" itemScope itemType="https://schema.org/Expert">
            {/* todo: make case where there's no avatar */}
            {!!review.authorUser.image?.threex && (
              <img
                itemProp="image"
                alt=""
                src={review.authorUser.image.threex}
                srcSet={`${review.authorUser.image.onex} 1x,
              ${review.authorUser.image.twox} 2x,
              ${review.authorUser.image.threex ?? review.authorUser.image.twox} 3x`} // 3x can be empty in SEO admin
              />
            )}
            {/* todo: use UserAvatar when 1x, 2x, 3x images are supported */}
            {!review.authorUser.image?.threex && (
              <div className="user-avatar user-avatar--empty">
                <Icon type={IconTypeEnum.User} />
              </div>
            )}
          </div>
          <div className="expert-review__middle">
            <div
              className="expert-review__middle-name"
              itemProp="author"
              itemScope
              itemType="http://schema.org/Person"
            >
              <h4 itemProp="name">{userName}</h4>
            </div>
            {!!dateString && <div className="expert-review__middle-date">{dateString}</div>}
          </div>
          <div className="expert-review__right">
            <div
              className="expert-review__right-rating"
              itemProp="reviewRating"
              itemScope
              itemType="http://schema.org/Rating"
            >
              <meta itemProp="ratingValue" content={review.rating.toString()} />
              <FeedbackSlider rating={review.rating} disabled iconSize={IconSizeEnum.Size24} />
            </div>
          </div>
        </div>
        <div className="expert-review__bottom" itemProp="reviewBody">
          {review.text}
        </div>
      </div>
    </>
  );
};

export default Review;

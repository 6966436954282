import { trimSlashes } from "@/utils/stringUtils";

import {
  BreadcrumbUrlPrefixEnum,
  FormattedBreadcrumbType,
  JsonLdBreadcrumbListType,
  JsonLdBreadcrumbType,
  SeoAdminBreadcrumbType,
} from "./types";

const formatBreadcrumb = (
  breadcrumb: SeoAdminBreadcrumbType,
  urlPrefix: BreadcrumbUrlPrefixEnum,
): FormattedBreadcrumbType =>
  ({
    id: breadcrumb.data.id,
    url: `/${urlPrefix}/${trimSlashes(breadcrumb.data?.attributes.URL)}`,
    displayName: breadcrumb.customDisplayName || breadcrumb.data?.attributes.breadcrumbDisplayName || "",
  });

export const formatBreadcrumbs = (
  seoId: number,
  url: string,
  breadcrumbDisplayName: string,
  breadcrumbs: SeoAdminBreadcrumbType[],
  urlPrefix: BreadcrumbUrlPrefixEnum,
): FormattedBreadcrumbType[] => {
  let result: FormattedBreadcrumbType[] = [{ id: 0, url: "/", displayName: "Главная" }];
  result = result.concat(breadcrumbs
    .filter((breadcrumb) =>
      !!breadcrumb.data)
  // Don't call reverse without copying array first - this will cause bug. .filter is fine because it creates a copy
    .reverse().map((breadcrumb) =>
      formatBreadcrumb(breadcrumb, urlPrefix)));
  result = result.concat({ id: seoId, url: `/${trimSlashes(url)}`, displayName: breadcrumbDisplayName });
  return result;
};

export const breadcrumbsToJsonLd = (breadcrumbs: FormattedBreadcrumbType[]): JsonLdBreadcrumbListType => {
  const websiteUrl = process.env.GATSBY_WEBSITE_URL || `https://lunaro.ru`;

  return {
    "@context": "https://schema.org",
    "@type": "BreadcrumbList",
    itemListElement: breadcrumbs.map((breadcrumb, index) => {
      const result: JsonLdBreadcrumbType = {
        "@type": "ListItem",
        position: index + 1,
        name: breadcrumb.displayName,
      };
      // Breadcrumb except for the last one should have item field
      if (index + 1 !== breadcrumbs.length) {
        result.item = `${websiteUrl}${breadcrumb.url !== "/" ? breadcrumb.url : ""}`;
      }
      return result;
    }),
  };
};

import { gql } from "@apollo/client";

export const CREATE_LEAD_DATA_MUTATION = gql`
  mutation createLeadData(
    $phone: String,
    $email: String,
    $utm: String!,
    $leadOrigin: String!,
    $data: String
  ) {
    createLeadData(input: {
      phone: $phone,
      email: $email,
      utm: $utm,
      leadOrigin: $leadOrigin,
      data: $data
    })
  }
`;

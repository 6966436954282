import { LeadFormFieldEnum } from "@/components/textPages/constructor/types";

export type FieldItemType = {
  id: string,
  type: LeadFormFieldEnum,
  title: string | null,
  hint: string | null,
  required: boolean | null,
  is18Plus?: boolean | null;
}

export type FilledFieldItemType = {
  [id: string]: { type: LeadFormFieldEnum, value?: string, errorText: string|null}
}

export enum FormStatusEnum {
  Ready = "Ready",
  Success = "Success",
  Error = "Error",
}

export type LeadFormProps = {
  formId: string,
  /** ID for Yandex Metrics goal */
  metricsId: string,
  /** URL or anchor to redirect user after lead form submitting */
  redirectUrl?: string | null,
  title?: string | null,
  description?: string | null,
  fieldItems: FieldItemType[],
  buttonText: string,
  buttonSubtext?: string | null,
  successTitle: string,
  successDescription?: string | null,
  backgroundUrl?: string;
  mobileBackgroundUrl?: string;
}

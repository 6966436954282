/* eslint-disable */
import React, { useEffect, useState } from "react";

import { Helmet } from "react-helmet";

import { QuizBlockProps } from "./types";

const QuizBlock = ({ quizId }: QuizBlockProps) => {
  useEffect(() => {
    if (!quizId) {
      return;
    }
    
    const script = document.createElement("script");
    script.innerHTML = `
    (function(t, p) {
      window.Marquiz
        ? Marquiz.add([t, p])
        : document.addEventListener(
          'marquizLoaded',
          function() {
            Marquiz.add([t, p])
          })
    })('Inline', {
      id: '${quizId}',
      buttonText: 'Пройти тест',
      bgColor: '#4E4898',
      textColor: '#ffffff',
      rounded: true,
      blicked: true,
      buttonOnMobile: true
    })`;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [quizId]);

  if (!quizId) {
    return null;
  }

  return (
    <>
      <Helmet>
        {/* <!-- Marquiz script start --> */}
        <script>
          {`
            (function(w, d, s, o){
              var j = d.createElement(s); j.async = true; j.src = '//script.marquiz.ru/v2.js';j.onload = function() {
                if (document.readyState !== 'loading') Marquiz.init(o);
                else document.addEventListener("DOMContentLoaded", function() {
                  Marquiz.init(o);
                });
              };
              d.head.insertBefore(j, d.head.firstElementChild);
            })(window, document, 'script', {
                host: '//quiz.marquiz.ru',
                region: 'eu',
                id: '${quizId}',
                autoOpen: false,
                autoOpenFreq: 'once',
                openOnExit: false,
                disableOnMobile: false
              }
            );
          `}
        </script>
        {/* <!-- Marquiz script end --> */}
      </Helmet>
      <div data-marquiz-id={quizId}></div>
    </>
  );
};

export default QuizBlock;

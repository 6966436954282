import { FieldItemType, FilledFieldItemType } from "./types";

export const getLeadFormInputId = (formId: string, id: number) =>
  `lead-form-${formId}-${id}`;

export const getSubmitError = (
  formWasSubmitted: boolean,
  fieldItem: FieldItemType,
  data: FilledFieldItemType,
) =>
  formWasSubmitted && (fieldItem.required ?? false) && !data[fieldItem.id]?.value;

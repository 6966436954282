import { useCallback, useContext } from "react";

import { useLocation } from "@reach/router";
import { navigate } from "gatsby";

import { setRedirectUrlSessionStorage } from "@/account/redirect";
import { FormTypeEnum } from "@/components/layout/modals/types";
import { setAuthParamToURL } from "@/components/layout/modals/utils";
import { UserContextType } from "@/contexts/User/types";
import UserContext from "@/contexts/User/UserContext";

/**
 * Returns function that shows login modal if non-registered user navigates to profile pages URL
 * or just navigates to URL otherwise
 * @returns
 */
const useNavigateToPrivatePath = () => {
  const location = useLocation();
  const { isUserLoggedIn } = useContext<UserContextType>(UserContext);

  const navigateToPrivatePath = useCallback((url: string) => {
    if (isUserLoggedIn || !url.includes("/profile")) {
      navigate(url);
    } else {
      setRedirectUrlSessionStorage(url);
      setAuthParamToURL(location, FormTypeEnum.Login);
    }
  }, [isUserLoggedIn, location]);

  return { navigateToPrivatePath };
};

export default useNavigateToPrivatePath;

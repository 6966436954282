import React from "react";

import { cleanupFromDangerousTags } from "@/utils/stringUtils";

import { getLeadFormInputId } from "../utils";

import { LeadFormItemHocProps } from "./types";
import "./styles.scss";

const LeadFormItemHoc = (props: LeadFormItemHocProps) => {
  const {
    formId,
    fieldItem: {
      id,
      required,
      title,
      hint,
    },
    hasSubmitError,
    errorText,
    children,
  } = props;

  return (
    <div className={`lead-form-item${(hasSubmitError || errorText) ? " error" : ""}`}>
      {(title || required) && (
        <div className="lead-form-item__title">
          {!!title && (
          // eslint-disable-next-line jsx-a11y/label-has-associated-control
          <label
            htmlFor={getLeadFormInputId(formId, id)}
            dangerouslySetInnerHTML={{ __html: cleanupFromDangerousTags(title) }}
          />
          )}
          {required && <div className="lead-form-item__asterisk">*</div>}
        </div>
      )}
      {children}
      {hasSubmitError && (
        <div className="lead-form-item__error-text">Введите данные</div>
      )}
      {errorText && (
        <div className="lead-form-item__error-text">{errorText}</div>
      )}
      {hint && (
        <div
          className="lead-form-item__hint"
          dangerouslySetInnerHTML={{ __html: `${cleanupFromDangerousTags(hint)}` }}
        />
      )}
    </div>
  );
};

export default LeadFormItemHoc;

import React from "react";

import defaultImage from "@images/seo/default-link-image.jpg";
import defaultImage2x from "@images/seo/default-link-image_@2x.jpg";

import { AllResolutionsImageProps } from "./types";

export const AllResolutionsImage = ({ imageData, imageDataDesktop }: AllResolutionsImageProps) => {
  if (!imageData) {
    return null;
  }

  return (
    <picture>
      <source
        media="(max-width: 767px)"
        srcSet={
          `${imageData.oneX.data?.attributes?.url ?? defaultImage},
          ${imageData.twoX.data?.attributes?.url ?? defaultImage2x} 2x,
          ${
            imageData.threeX?.data?.attributes?.url
            ?? imageData.twoX.data?.attributes?.url ?? defaultImage2x
          }, 3x
          `
        }
      />
      <source
        media="(min-width: 768px)"
        srcSet={
          `${imageDataDesktop?.oneX.data?.attributes?.url ?? imageData.oneX.data?.attributes?.url ?? defaultImage},
          ${imageDataDesktop?.twoX.data?.attributes?.url ?? imageData.twoX.data?.attributes?.url ?? defaultImage2x} 2x,
          ${
            imageDataDesktop?.threeX?.data?.attributes?.url
            ?? imageDataDesktop?.twoX.data?.attributes?.url
            ?? imageData.threeX?.data?.attributes?.url
            ?? imageData.twoX?.data?.attributes?.url
            ?? defaultImage2x
          }, 3x
          `
        }
      />
      <img
        src={
          imageData.twoX?.data?.attributes?.url || defaultImage2x
        }
        alt=""
      />
    </picture>
  );
};

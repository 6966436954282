import React from "react";

import { Link } from "gatsby";

import { ButtonSizeEnum } from "@/components/common/button";
import { WebsiteSectionUrl } from "@/components/constants";
import { cleanupFromDangerousTags } from "@/utils/stringUtils";

import { AllResolutionsImage } from "../allResolutionsImage";
import ButtonLink from "../buttonLink";

import { CardTableProps } from "./types";

import "./styles.scss";

const CardTable = ({ data } : CardTableProps) =>
  (
    <div className={`card-table ${data.cardTableSize}`}>
      {data.cardTableItems?.map((item) => {
        const cardTitle = item?.customTitle ?? item?.article?.data?.attributes?.h1 ?? "";
        const cardDescription = item?.customDescription ?? item?.article?.data?.attributes?.description ?? "";

        return (
          <div className="card-table__item" key={item?.id}>
            <div className="card-table__image">
              <AllResolutionsImage imageData={item?.customImage ?? item?.article?.data?.attributes?.image} />
            </div>
            {cardTitle && item?.article?.data?.attributes?.URL && (
              <Link to={`/${WebsiteSectionUrl.Article}${item?.article?.data?.attributes?.URL}`}>
                <div
                  className="card-table__title"
                  dangerouslySetInnerHTML={{
                    __html: cleanupFromDangerousTags(cardTitle),
                  }}
                />
              </Link>
            )}
            {cardTitle && !item?.article?.data?.attributes?.URL && (
              <div
                className="card-table__title"
                dangerouslySetInnerHTML={{
                  __html: cleanupFromDangerousTags(cardTitle),
                }}
              />
            )}
            {cardDescription && (
              <div
                className="card-table__description"
                dangerouslySetInnerHTML={{
                  __html: cleanupFromDangerousTags(cardDescription),
                }}
              />
            )}
            {item?.button
              && (
                <div className="card-table__button">
                  <ButtonLink data={item.button} size={ButtonSizeEnum.Small} />
                </div>
              )}
          </div>
        );
      })}
    </div>
  );

export default CardTable;

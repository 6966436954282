import * as React from "react";

import { Icon, IconTypeEnum } from "@components/common/icon";

import { STEP_BLOCK_ARROW_CSS_CLASSES } from "./constants";
import { StepBlockItem, StepBlockProps } from "./types";
import "./styles.scss";

const StepBlock = ({ header, items }: StepBlockProps) =>
  (
    <>
      {header && (
        <h2 className="h1 main-page__title --left step-block__title">
          {header}
        </h2>
      )}
      <div className="step-block__steps">
        {items.map((item: StepBlockItem, index: number) =>
          (
            <React.Fragment key={item.id ?? index}>
              <div className="step-block__step">
                <div className="h3 step-block__step-title">{item.title}</div>
                {!!item.description && <div className="step-block__step-text">{item.description}</div>}
              </div>
              <div className={`step-block__arrow ${STEP_BLOCK_ARROW_CSS_CLASSES[index % 3]}`}>
                <Icon type={IconTypeEnum.Right} />
              </div>
            </React.Fragment>
          ))}
      </div>
    </>
  );

export default StepBlock;

import * as React from "react";

import { Link } from "gatsby";
import { Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import ExpertShowcase from "@/components/common/showcaseBlock/ExpertShowcase";
import useNavigateToPrivatePath from "@/hooks/useNavigateToPrivatePath";
import { customMarketingHandler, marketingMainPage1 } from "@/marketing/utils";
import {
  Button,
  ButtonColorEnum,
  ButtonIconPositionEnum,
  ButtonSizeEnum,
} from "@components/common/button";
import { Icon, IconSizeEnum, IconTypeEnum } from "@components/common/icon";

import { CarouselItemType, ShowcaseBlockProps } from "./types";

// eslint-disable-next-line import/no-unresolved
import "swiper/css";
import "./styles.scss";

const ShowcaseBlock = (props: ShowcaseBlockProps) => {
  const {
    title,
    carouselItems,
    buttonText,
    buttonSubtext,
    buttonLink,
    labels,
    slantedlabels,
    expertData,
    imageData,
    buttonMetricsId,
  } = props;

  const { navigateToPrivatePath } = useNavigateToPrivatePath();

  const handleButtonClick = () => {
    if (buttonMetricsId) {
      customMarketingHandler(buttonMetricsId);
    }
    marketingMainPage1();
    navigateToPrivatePath(buttonLink);
  };

  const slantedLabelWithIconIndex = slantedlabels
  && slantedlabels.length > 1
  && slantedlabels[1].content.length > slantedlabels[0].content.length
    ? 1 : 0;

  return (
    <div className="showcase-block">
      <div className="showcase-block__info">
        <h1 className="showcase-block__title">
          {title}
        </h1>
        <Swiper
          loop
          direction="vertical"
          autoplay={{
            delay: 2000,
          }}
          modules={[Autoplay]}
          className="showcase-block__slider"
        >
          {carouselItems.map((carouselItem: CarouselItemType) =>
            (
              <SwiperSlide className="showcase-block__slide" key={carouselItem.id}>
                <div className="showcase-block__description">{carouselItem.content}</div>
              </SwiperSlide>
            ))}
        </Swiper>
        <div className="showcase-block__btn">
          <Link to={buttonLink}>
            <Button
              text={buttonText}
              subtext={buttonSubtext}
              size={ButtonSizeEnum.Large}
              color={ButtonColorEnum.Black}
              iconPosition={ButtonIconPositionEnum.Right}
              onClick={handleButtonClick}
            />
          </Link>
          {!!labels?.length && (
            <div className="showcase-block__btn-subtext">
              {labels.map((label) =>
                (
                  <span key={label.id} className={`${(labels.length > 1 && slantedlabels?.length) ? "margin-right" : ""}`}>{label.content}</span>
                ))}
            </div>
          )}
          {!!slantedlabels?.length && (
            <div className={`showcase-block__action-container ${
              slantedlabels && slantedlabels.length > 1 ? " showcase-block__action-container--long" : ""}`}
            >
              <div className={`showcase-block__action${
                labels && labels.length > 1 ? " showcase-block__action--long" : ""
              }${
                !labels || labels.length === 0 ? " showcase-block__action--low" : ""
              }`}
              >
                <div className="showcase-block__action-text">
                  {slantedlabels?.map((slantedlabel, index) =>
                    (
                      <div>
                        {index === slantedLabelWithIconIndex
                          ? (
                            <>
                              <span key={slantedlabel.id}>{slantedlabel.content}</span>
                              <Icon size={IconSizeEnum.Size36} type={IconTypeEnum.Fire} />
                            </>
                          )
                          : <span key={slantedlabel.id}>{slantedlabel.content}</span>}
                      </div>
                    ))}
                </div>
                <div className="showcase-block__action-icon" />
              </div>
            </div>
          )}
        </div>
      </div>
      <div className={`showcase-block__expert${(!labels?.length && !slantedlabels?.length) ? " showcase-block__expert--long" : ""}`}>
        <ExpertShowcase imageData={imageData} expertData={expertData} />
      </div>
    </div>
  );
};

export default ShowcaseBlock;

import React from "react";

import { navigate } from "gatsby";

import { Button, ButtonColorEnum } from "@components/common/button";
import ExpertCard from "@components/common/expertCard";
import { CATALOG_PAGE_NAME } from "@components/constants";

import { SeoExpertType } from "../types";

import { ExpertsBlockProps } from "./types";

import "./styles.scss";

const ExpertsBlock = ({ header, experts, showCatalogButton }: ExpertsBlockProps) =>
  (
    <div className="experts-block">
      {header && <h2>{header}</h2>}
      {experts.map((edge: SeoExpertType, index: number) =>
        (
          <div className="experts-block__item" key={edge.node.id}>
            <ExpertCard expert={edge.node} expertCardPosition={index} />
          </div>
        ))}
      {showCatalogButton && (
        <Button
          text="Перейти в каталог"
          color={ButtonColorEnum.Light}
          className="experts-block__button"
          onClick={() =>
            navigate(`/${CATALOG_PAGE_NAME}`)}
        />
      )}
    </div>
  );

export default ExpertsBlock;

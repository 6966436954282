import * as React from "react";

import { cleanupFromDangerousTags } from "@/utils/stringUtils";

import { CardBlockItem, CardBlockProps } from "./types";
import "./styles.scss";

const CardBlock = ({ header, items }: CardBlockProps) =>
  (
    <>
      {header && (
        <h2 className="h1 card-block__title">
          {header}
        </h2>
      )}
      <div className="card-block__items">
        {items.map((item: CardBlockItem) =>
          (
            <div className="card-block__item" key={item.id}>
              {!!item.icon && <img src={item.icon} alt="" />}
              {!!item.description && (
              <div
                className="card-block__item-text"
                dangerouslySetInnerHTML={{
                  __html: cleanupFromDangerousTags(item.description || ""),
                }}
              />
              )}
            </div>
          ))}
      </div>
    </>
  );

export default CardBlock;

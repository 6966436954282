import React, { useEffect, useState } from "react";

import { Helmet } from "react-helmet";

import { Button, ButtonColorEnum } from "../button";
import { Icon, IconSizeEnum, IconTypeEnum } from "../icon";

import { StickyButtonProps } from "./types";
import "./styles.scss";

const StickyButton = ({
  stickyButtonText, onClick, stickyButtonSubtext, stickyButtonShowLabel,
}: StickyButtonProps) => {
  const [isStickyButtonShown, setIsStickyButtonShown] = useState(false);

  useEffect(() => {
    // Функция для отслеживания скролла
    const handleScroll = () => {
      // Scroll values are different for mobile and desktop for some reason
      const scrollY = Math.max(window.scrollY, document.body.scrollTop);
      const documentHeight = Math.max(
        document.body.scrollHeight - document.body.offsetHeight,
        document.documentElement.scrollHeight - document.documentElement.clientHeight,
      );
      setIsStickyButtonShown(scrollY > 0 && scrollY < documentHeight);
    };
    document.body.addEventListener("scroll", handleScroll);
    window.addEventListener("scroll", handleScroll);

    return () => {
      // For desktop
      document.body.removeEventListener("scroll", handleScroll);
      // For mobile
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <Helmet>
        <body
          className={`${
            isStickyButtonShown && "carrot-quest--mobile-seo-page"
          }`}
        />
      </Helmet>
      <div className={`sticky-button__container page-width${isStickyButtonShown ? " sticky-button__container--show" : ""}`}>
        <div className="sticky-button__inner">
          <div className="sticky-button">
            <Button
              text={stickyButtonText}
              color={ButtonColorEnum.Black}
              onClick={onClick}
              subtext={stickyButtonSubtext}
            />
            {stickyButtonShowLabel && (
              <div className="sticky-button__action-container">
                <Icon size={IconSizeEnum.Size36} type={IconTypeEnum.Fire} />
                <div className="sticky-button__action">
                  <div className="sticky-button__action-text">
                    <span>1-я консультация</span>
                    <span>бесплатная</span>
                  </div>
                  <div className="sticky-button__action-icon" />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default StickyButton;

import * as React from "react";

import checkGreen from "@images/checkGreen.svg";

import { BulletBlockProps } from "./types";
import "./styles.scss";

const BulletBlock = ({ items }: BulletBlockProps) =>
  (
    <div className="bullet-block__items">
      {items.map((item, index) =>
        (
          <div className="bullet-block__item" key={item.id ?? index}>
            <img src={checkGreen} alt="" className="bullet-block__item-icon" />
            <div className="h4 bullet-block__item-label">{item.title}</div>
            {!!item.description && <div className="bullet-block__item-text">{item.description}</div>}
          </div>
        ))}
    </div>
  );

export default BulletBlock;

export type FormattedBreadcrumbType = { id: number; url: string; displayName: string };

export type JsonLdBreadcrumbType = {
  "@type": string;
  position: number;
  name: string;
  item?: string;
};

export type JsonLdBreadcrumbListType = {
  "@context": string;
  "@type": string;
  itemListElement: JsonLdBreadcrumbType[];
};

export type SeoAdminBreadcrumbType = {
  customDisplayName: string;
  data: {
    id: number;
    attributes: {
      URL: string;
      breadcrumbDisplayName: string;
      publishedAt: string;
    };
  };
};

export enum BreadcrumbUrlPrefixEnum {
  Page = "",
  Article = "articles",
  Catalog = "catalog",
  Category = "category",
  Gid = "gidy",
  Goroskop = "goroskop",
}

export type BreadcrumbsProps = {
  seoId: number;
  url: string;
  breadcrumbDisplayName?: string;
  breadcrumbs?: SeoAdminBreadcrumbType[];
  urlPrefix: BreadcrumbUrlPrefixEnum;
  showOnPage?: boolean;
};

import { BulletBlockItem } from "@/components/common/bulletBlock/types";
import { CardBlockItem } from "@/components/common/cardBlock/types";
import { FieldItemType } from "@/components/common/leadForm/types";
import { ReviewType } from "@/components/common/review/types";
import {
  CarouselItemType,
  ExpertShowcaseImageType,
  LabelItemType,
  SlantedLabelItemType,
} from "@/components/common/showcaseBlock/types";
import { StepBlockItem } from "@/components/common/stepBlock/types";

import { LeadFormFieldEnum, ZoneItemEnum, ZoneItemType } from "./types";

export const convertToCardBlockItems = (
  items: readonly Queries.Maybe<Queries.Seo_ComponentBasicCardBlockItem>[],
): CardBlockItem[] =>
  (items ?? []).reduce<CardBlockItem[]>((accumulator, item) => {
    if (item) {
      accumulator.push({
        id: item.id,
        icon: item.cardBlockIcon?.data?.attributes?.url,
        description: item.cardBlockDescription,
      });
    }
    return accumulator;
  }, []);

export const convertToBulletBlockItems = (
  items: readonly Queries.Maybe<Queries.Seo_ComponentBasicBulletBlockItem>[],
): BulletBlockItem[] =>
  (items ?? []).reduce<BulletBlockItem[]>((accumulator, item) => {
    if (item) {
      accumulator.push({
        id: item.id,
        icon: item.bulletBlockIcon?.data?.attributes?.url,
        title: item.bulletBlockTitle,
        description: item.bulletBlockDescription,
      });
    }
    return accumulator;
  }, []);

export const convertToStepBlockItems = (
  items: readonly Queries.Maybe<Queries.Seo_ComponentBasicStepBlockItem>[],
): StepBlockItem[] =>
  (items ?? []).reduce<StepBlockItem[]>((accumulator, item) => {
    if (item) {
      accumulator.push({
        id: item.id,
        title: item.stepBlockTitle,
        description: item.stepBlockDescription,
      });
    }
    return accumulator;
  }, []);

export const convertToCarouselItems = (
  items: readonly Queries.Maybe<Queries.Seo_ComponentBasicSimpleTextField>[],
): CarouselItemType[] =>
  (items || []).reduce<CarouselItemType[]>((accumulator, item) => {
    if (item) {
      accumulator.push({
        id: item.id,
        content: item.simpleTextContent,
      });
    }
    return accumulator;
  }, []);

export const convertToLabelItems = (
  items: Queries.Maybe<readonly Queries.Maybe<Queries.Seo_ComponentBasicSimpleTextField>[]>,
): LabelItemType[] =>
  (items || []).reduce<LabelItemType[]>((accumulator, item) => {
    if (item) {
      accumulator.push({
        id: item.id,
        content: item.simpleTextContent,
      });
    }
    return accumulator;
  }, []);

export const convertToSlantedLabelItems = (
  items: Queries.Maybe<readonly Queries.Maybe<Queries.Seo_ComponentBasicSimpleTextField>[]>,
): SlantedLabelItemType[] =>
  (items || []).reduce<SlantedLabelItemType[]>((accumulator, item) => {
    if (item) {
      accumulator.push({
        id: item.id,
        content: item.simpleTextContent,
      });
    }
    return accumulator;
  }, []);

export const convertToShowcaseImage = (
  item: Queries.Seo_ComponentBasicAllResolutionsImage,
): ExpertShowcaseImageType =>
  ({
    oneX: item.oneX.data?.attributes?.url ?? "",
    twoX: item.twoX.data?.attributes?.url ?? "",
    threeX: item.threeX?.data?.attributes?.url ?? item.twoX.data?.attributes?.url ?? "",
  });

export const convertToReviewItem = (
  item: Queries.Seo_ComponentBasicReviewBlockItem,
): ReviewType =>
  ({
    id: parseInt(item.id, 10),
    text: item.reviewBlockDescription ?? "",
    rating: item.reviewBlockStarAmount,
    authorUser: {
      image: {
        onex: item.reviewBlockImage?.data?.attributes?.url ?? "",
        twox: item.reviewBlockImage?.data?.attributes?.url ?? "",
        threex: item.reviewBlockImage?.data?.attributes?.url ?? "",
      },
      name: item.reviewBlockTitle,
    },
  });

export const convertToLeadFormPhones = (
  leadFormPhones: Queries.Maybe<readonly Queries.Maybe<Queries.Seo_ComponentFormFormPhone>[]>,
  startIndex: number,
): FieldItemType[] =>
  (leadFormPhones || [])
    .reduce<FieldItemType[]>((accumulator, item, index: number) => {
      if (item) {
        accumulator.push({
          id: `${LeadFormFieldEnum.Phone}${index + startIndex}`,
          type: LeadFormFieldEnum.Phone,
          title: item?.formPhoneTitle,
          hint: item?.formPhoneDescription,
          required: item?.formPhoneRequired,
        });
      }
      return accumulator;
    }, []);

export const convertToLeadFormEmails = (
  leadFormEmails: Queries.Maybe<readonly Queries.Maybe<Queries.Seo_ComponentFormFormEmail>[]>,
  startIndex: number,
): FieldItemType[] =>
  (leadFormEmails || [])
    .reduce<FieldItemType[]>((accumulator, item, index: number) => {
      if (item) {
        accumulator.push({
          id: `${LeadFormFieldEnum.Email}${index + startIndex}`,
          type: LeadFormFieldEnum.Email,
          title: item?.formEmailTitle,
          hint: item?.formEmailDescription,
          required: item?.formEmailRequired,
        });
      }
      return accumulator;
    }, []);

export const convertToLeadFormDates = (
  leadFormDates: Queries.Maybe<readonly Queries.Maybe<Queries.Seo_ComponentFormFromDate>[]>,
  startIndex: number,
)
: FieldItemType[] =>
  (leadFormDates || [])
    .reduce<FieldItemType[]>((accumulator, item, index: number) => {
      if (item) {
        accumulator.push({
          id: item?.formDateExternalId ? item?.formDateExternalId : `${LeadFormFieldEnum.Date}${index + startIndex}`,
          type: LeadFormFieldEnum.Date,
          title: item?.formDateTitle,
          hint: item?.formDateDescription,
          required: !!item?.formDateRequired,
          is18Plus: !!item?.formDate18Plus,
        });
      }
      return accumulator;
    }, []);

export const getFirstImageFromZone = (zone: ZoneItemType[]) => {
  const foundShowcaseBlock = zone.find((zoneItem: ZoneItemType) =>
    zoneItem.__typename === ZoneItemEnum.Seo_ComponentBlockShowcaseBlock);

  if (foundShowcaseBlock) {
    return (foundShowcaseBlock as Queries.Seo_ComponentBlockShowcaseBlock)
      ?.showcaseBlockImage?.twoX?.data?.attributes?.url;
  }

  const foundTextBlock = zone.find((zoneItem: ZoneItemType) =>
    zoneItem.__typename === ZoneItemEnum.Seo_ComponentBasicTextField);

  if (foundTextBlock) {
    return (foundTextBlock as Queries.Seo_ComponentBasicTextField)
      ?.textContent?.match(/src\s*=\s*"(.+?)"/)?.[1];
  }

  return null;
};

import React, { useEffect, useRef, useState } from "react";

import { useMutation } from "@apollo/client";
import { navigate } from "gatsby";

import { getLeadFormSentValue, setLeadFormSentValue } from "@/account/landings";
import { LeadFormFieldEnum } from "@/components/textPages/constructor/types";
import { useManageUtmData } from "@/hooks/useManageUtmData";
import { customMarketingHandler } from "@/marketing/utils";
import { cleanupFromDangerousTags } from "@/utils/stringUtils";
import { Button, ButtonColorEnum, ButtonSizeEnum } from "@components/common/button";
import { Icon, IconSizeEnum, IconTypeEnum } from "@components/common/icon";
import BirthDate from "@components/common/Inputs/BirthDate";
import EmailInput from "@components/common/Inputs/emailInput";
import PhoneInput from "@components/common/Inputs/phoneInput";

import { createLeadData as createLeadDataType, createLeadDataVariables } from "./graphql/__generated__/createLeadData";
import { CREATE_LEAD_DATA_MUTATION } from "./graphql/CREATE_LEAD_DATA_MUTATION";
import LeadFormItemHoc from "./leadFormItemHoc";
import { FilledFieldItemType, FormStatusEnum, LeadFormProps } from "./types";
import { getLeadFormInputId, getSubmitError } from "./utils";
import "./styles.scss";

const LeadForm = (props: LeadFormProps) => {
  const {
    formId,
    metricsId,
    redirectUrl,
    title,
    description,
    fieldItems,
    buttonText,
    buttonSubtext,
    successTitle,
    successDescription,
    backgroundUrl,
    mobileBackgroundUrl,
  } = props;

  const [data, setData] = useState<FilledFieldItemType>({});
  const [formStatus, setFormStatus] = useState(FormStatusEnum.Ready);
  const [formWasSubmitted, setFormWasSubmitted] = useState<boolean>(false);
  const formRef = useRef(null);

  const [createLeadDataMutation, {
    data: mutationData,
    error: mutationError,
    loading: mutationLoading,
  }] = useMutation<
    createLeadDataType,
    createLeadDataVariables
  >(CREATE_LEAD_DATA_MUTATION);

  useEffect(() => {
    if (mutationData && !mutationLoading) {
      formRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
      if (!mutationError) {
        // Result flag indicates if user already exists in database
        if (!mutationData.createLeadData) {
          // If not - marketing goal is reached
          customMarketingHandler(metricsId);
        }
        if (redirectUrl && redirectUrl.startsWith("#")) {
          setFormStatus(FormStatusEnum.Success);
          navigate(redirectUrl);
        } else if (redirectUrl) {
          navigate(redirectUrl);
        } else {
          setFormStatus(FormStatusEnum.Success);
        }
      } else {
        setFormStatus(FormStatusEnum.Error);
      }
    }
  }, [metricsId, mutationData, mutationError, mutationLoading, redirectUrl]);

  const { getUtmData } = useManageUtmData();

  const isFormValid = fieldItems
    .every((field) =>
      (!data[field.id]?.errorText ?? true) && (!field.required || !!data[field.id]?.value));

  const updateData = (
    key: number,
    type: LeadFormFieldEnum,
    value: string,
    errorText: string|null,
  ) => {
    setData((oldVal) =>
      ({ ...oldVal, [key.toString()]: { type, value, errorText } }));
  };

  const handleSubmit = () => {
    setFormWasSubmitted(true);

    if (!isFormValid) {
      return;
    }
    if (!getLeadFormSentValue()) {
      setLeadFormSentValue(formId);
    }
    const formattedData = Object.keys(data).reduce(
      (acc, key: string) =>
        ({ ...acc, [key]: data[key].value }),
      {},
    );
    const phoneData = Object.values(data).find((value) =>
      value.type === LeadFormFieldEnum.Phone)?.value;
    const emailData = Object.values(data).find((value) =>
      value.type === LeadFormFieldEnum.Email)?.value;

    createLeadDataMutation({
      variables: {
        phone: phoneData,
        email: emailData,
        utm: getUtmData() ?? "",
        leadOrigin: formId,
        data: JSON.stringify(formattedData),
      },
    });
  };

  return (
    <div
      ref={formRef}
      id={formId}
      className="lead-form__container"
    >
      {(mobileBackgroundUrl || backgroundUrl)
        ? (
          <picture>
            <source
              srcSet={`${mobileBackgroundUrl || backgroundUrl}`}
              media="(max-width: 767px)"
            />
            <source
              srcSet={`${backgroundUrl || mobileBackgroundUrl}`}
              media="(min-width: 768px)"
            />
            <img
              src={mobileBackgroundUrl || backgroundUrl}
              alt=""
              className="lead-form__bg"
            />
          </picture>
        )
        : <div className="lead-form__bg" />}
      {formStatus === FormStatusEnum.Success && (
        <div className="lead-form__result">
          <Icon type={IconTypeEnum.Tick} size={IconSizeEnum.Size48} />
          <div className="lead-form__result-header" dangerouslySetInnerHTML={{ __html: cleanupFromDangerousTags(successTitle) }} />
          {successDescription && <div className="lead-form__result-text" dangerouslySetInnerHTML={{ __html: cleanupFromDangerousTags(successDescription) }} />}
        </div>
      )}
      {formStatus === FormStatusEnum.Error && (
        <div className="lead-form__result">
          <Icon type={IconTypeEnum.Error} size={IconSizeEnum.Size48} />
          <div className="lead-form__result-header">Что-то пошло не так&hellip;</div>
          <div className="lead-form__result-text">Попробуйте ещё раз или напишите в&nbsp;службу поддержки</div>
          <Button
            text="Попробовать ещё раз"
            size={ButtonSizeEnum.Large}
            color={ButtonColorEnum.Light}
            onClick={() => { setFormStatus(FormStatusEnum.Ready); }}
          />
        </div>
      )}
      <div className={`lead-form${([FormStatusEnum.Success, FormStatusEnum.Error].includes(formStatus)) ? " hidden" : ""}`}>
        {title && <div className="lead-form__title" dangerouslySetInnerHTML={{ __html: cleanupFromDangerousTags(title) }} />}
        {description && <div className="lead-form__description" dangerouslySetInnerHTML={{ __html: cleanupFromDangerousTags(description) }} />}
        <div className="lead-form__items">
          {fieldItems.map((fieldItem) => {
            switch (fieldItem.type) {
              case LeadFormFieldEnum.Phone: return (
                <LeadFormItemHoc
                  formId={formId}
                  fieldItem={fieldItem}
                  key={fieldItem.id}
                  hasSubmitError={getSubmitError(formWasSubmitted, fieldItem, data)}
                  errorText={data[fieldItem.id]?.errorText}
                >
                  <PhoneInput
                    id={getLeadFormInputId(formId, fieldItem.id)}
                    phoneInitial=""
                    setPrevValueCallback={() => {}}
                    onChange={(value: string, errorText: string | null) => {
                      updateData(fieldItem.id, fieldItem.type, value, errorText);
                    }}
                    onSubmit={handleSubmit}
                  />
                </LeadFormItemHoc>
              );
              case LeadFormFieldEnum.Email: return (
                <LeadFormItemHoc
                  formId={formId}
                  fieldItem={fieldItem}
                  key={fieldItem.id}
                  hasSubmitError={getSubmitError(formWasSubmitted, fieldItem, data)}
                  errorText={data[fieldItem.id]?.errorText}
                >
                  <EmailInput
                    id={getLeadFormInputId(formId, fieldItem.id)}
                    initaiEmail=""
                    onChange={(value: string, errorText: string | null) => {
                      updateData(fieldItem.id, fieldItem.type, value, errorText);
                    }}
                    onSubmit={handleSubmit}
                  />
                </LeadFormItemHoc>
              );
              case LeadFormFieldEnum.Date: return (
                <LeadFormItemHoc
                  formId={formId}
                  fieldItem={fieldItem}
                  key={fieldItem.id}
                  hasSubmitError={getSubmitError(formWasSubmitted, fieldItem, data)}
                  errorText={data[fieldItem.id]?.errorText}
                >
                  <BirthDate
                    id={getLeadFormInputId(formId, fieldItem.id)}
                    initialValue=""
                    isUnderageAllowed={!fieldItem.is18Plus}
                    setDateCallback={(value: string, errorText: string | null) => {
                      updateData(fieldItem.id, fieldItem.type, value, errorText);
                    }}
                    enterPressCallback={handleSubmit}
                  />
                </LeadFormItemHoc>
              );
              default: return null;
            }
          })}
          <Button
            text={buttonText}
            subtext={buttonSubtext}
            size={ButtonSizeEnum.Large}
            color={ButtonColorEnum.Dark}
            onClick={handleSubmit}
            disabled={!isFormValid}
            isLoading={mutationLoading}
          />
        </div>
        <div className="lead-form__agreement">
          Оставляя данные, вы даёте согласие на их
          {" "}
          <a href="/privacy-policy" target="_blank" rel="noreferrer">обработку</a>
        </div>
      </div>
    </div>
  );
};

export default LeadForm;

import React, { useEffect, useRef, useState } from "react";

import { Icon, IconTypeEnum } from "@components/common/icon";

import { AccordionProps } from "./types";
import { accordionBlockLogic, transitionEndCallback } from "./utils";

import "./style.scss";

const Accordion = ({ summary, details, className = "" }: AccordionProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const contentRef = useRef<HTMLDivElement | null>(null);

  function onClickHandler() {
    const accordionBody = contentRef.current;

    if (!accordionBody) {
      return;
    }

    setIsOpen((prevState) => {
      accordionBlockLogic(prevState, accordionBody);

      return !prevState;
    });
  }

  useEffect(() => {
    const accordionBody = contentRef.current;

    if (accordionBody) {
      accordionBody.addEventListener("transitionend", () =>
        transitionEndCallback(accordionBody));
    }

    return () => {
      if (accordionBody) {
        accordionBody.removeEventListener("transitionend", () =>
          transitionEndCallback(accordionBody));
      }
    };
  }, []);

  return (
    <div
      className={`accordion ${className} ${isOpen ? "--open" : ""}`}
      itemProp="mainEntity"
      itemScope
      itemType="http://schema.org/Question"
    >
      <div className="accordion__inner">
        <div className="accordion__header" onClick={onClickHandler}>
          <h4 itemProp="name" className="accordion__title">
            {summary}
          </h4>
          <div className="accordion__icon">
            <Icon type={IconTypeEnum.Dropdown} />
          </div>
        </div>
        <div
          className="accordion__body"
          ref={contentRef}
          itemScope
          itemProp="answer"
          itemType="https://schema.org/Answer"
        >
          <p itemProp="text" className="accordion__description">
            {details}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Accordion;

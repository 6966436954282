import { ExpertState } from "@/autoGeneratedGlobalTypes";

import { GetCategoryExperts_getExperts_edges } from "./category/graphql/__generated__/GetCategoryExperts";
import { AVERAGE_READING_SPEED_PER_MINUTE, SHOWN_EXPERTS_COUNT } from "./constants";
import { LinkUrlPrefixEnum, SeoAdminLinkType } from "./types";

const shuffleExperts = (array: GetCategoryExperts_getExperts_edges[]) =>
  array.sort(() =>
    0.5 - Math.random());

export const buildExpertList = (
  allExperts: GetCategoryExperts_getExperts_edges[],
  maxExpertCount?: number,
  showBusy?: boolean,
) => {
  const onlineExperts = allExperts.filter((expert) =>
    expert.node.state === ExpertState.available);
  const offlineExperts = allExperts.filter((expert) =>
    expert.node.state === ExpertState.offline);
  const busyExperts = allExperts.filter((expert) =>
    expert.node.state === ExpertState.busy);

  const fullExpertList = [
    ...shuffleExperts(onlineExperts),
    ...(showBusy ? shuffleExperts(busyExperts) : []),
    ...shuffleExperts(offlineExperts),
  ];

  if (!maxExpertCount) {
    return fullExpertList;
  }

  return fullExpertList.slice(0, maxExpertCount);
};

export const buildSpecificExpertList = (
  expertIds: (number | undefined)[],
  allExperts: GetCategoryExperts_getExperts_edges[],
) =>
  buildExpertList(allExperts.filter((expert) =>
    expertIds.includes(expert.node.id)), SHOWN_EXPERTS_COUNT, true);

export const getLinkBlockObject = (links: SeoAdminLinkType[], urlPrefix: LinkUrlPrefixEnum) =>
  links.map((link) =>
    ({ link, urlPrefix }));

export const getReadDurationMinByText = (text: string) =>
  Math.ceil(
    (text.length || 0) / AVERAGE_READING_SPEED_PER_MINUTE,
  );

export const shouldLinkBeBullet = (length: number) =>
  length <= 7;

export const shuffleArrayById = (array: Queries.Seo_ArticleEntityResponse[], id: number) => {
  let seed: number = id * 123456;
  const newArray = [...array];

  const random = () => {
    const x = Math.sin(seed++) * 10000;
    return x - Math.floor(x);
  };

  for (let i = newArray.length - 1; i > 0; i--) {
    const j = Math.floor(random() * (i + 1));
    [newArray[i], newArray[j]] = [newArray[j], newArray[i]];
  }

  return newArray;
};

import React, { useState } from "react";

import { EmailInputProps } from "./types";
import { getEmailError, isEmailValid } from "./utils";

const EmailInput = (props: EmailInputProps) => {
  const {
    id,
    initaiEmail,
    onChange,
    onSubmit,
  } = props;

  const [email, setEmail] = useState<string>(initaiEmail);
  const [isEmailTouched, setIsEmailTouched] = useState<boolean>(false);
  const emailChangeLoader = false; // todo: probably delete

  const onKeyDownHandler = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.code === "Enter" || event.code === "NumpadEnter") {
      event.preventDefault();
      setIsEmailTouched(true);
      onChange(email, getEmailError(email));
      onSubmit();
    }
  };

  return (
    <input
      id={id}
      type="email"
      className={isEmailTouched && !isEmailValid(email) ? " error" : ""}
      name="email"
      aria-invalid="true"
      defaultValue={email}
      onChange={(event) => {
        setEmail(event.target.value);
        setIsEmailTouched(false);
        return onChange(event.target.value, getEmailError(event.target.value));
      }}
      onBlur={() =>
        setIsEmailTouched(true)}
      onKeyDown={(e) =>
        onKeyDownHandler(e)}
      disabled={emailChangeLoader}
      placeholder="maria@mail.ru"
    />
  );
};
export default EmailInput;

import * as React from "react";

import { navigate } from "gatsby";

import { ExpertShowcaseProps } from "./types";
import "./styles.scss";

const ExpertShowcase = ({ imageData, expertData }: ExpertShowcaseProps) =>
  (
    <div className="expert-showcase">
      <div className="expert-showcase__inner">
        <picture>
          <source
            srcSet={`${imageData.oneX},
                      ${imageData.twoX} 2x,
                      ${imageData.threeX ?? imageData.twoX} 3x`}
          />
          <img src={imageData.twoX} alt="" className="expert-showcase__img" />
        </picture>
      </div>
      {!!expertData && (
      <div className="expert-showcase__info-container">
        <div className="expert-showcase__info">Эксперт Lunaro</div>
        <span
          onClick={() =>
            navigate(expertData.linkUrl)}
          className="expert-showcase__name"
        >
          {expertData.expertName}
        </span>
      </div>
      )}
    </div>
  );

export default ExpertShowcase;
